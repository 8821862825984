import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import { auth } from "../components/Firebase/Firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import Layout from "../components/Layout";
import View from "../components/View";
import * as Styles from '../styles/index.module.scss'

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const validatePassword = () => {
    let isValid = true;
    if (password !== "" && confirmPassword !== "") {
      if (password !== confirmPassword) {
        isValid = false;
        setError("Passwords does not match");
      }
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      if (validatePassword()) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        await sendEmailVerification(userCredential.user);
        signOut(auth);
        navigate(`/login`);
      }
    } catch (err) {
      setError(err.message);
    }
    setEmail("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <Layout title="">
      <View >
        <div className={Styles.instructions}>
        <h1>Im Lernportal registrieren</h1>

          <p>Sie können sich hier registrieren, um sich am Lernportal von Brandeis Consulting anzumelden. Dadurch können Sie aber nicht automatisch Inhalte sehen. Die Zuordnung der Inhalte erfolgt separat. Bitte registrieren Sie sich mit Ihrer beruflichen E-Mailadresse. </p>
          <p>Ihre Zugangsdaten (E-Mail-Adresse & Kennwort) werden beim Abschicken der Registrierung auf den Servern des Firebase Dienstes der Firma Google gespeichert. Beim Anmelden auf unserer Seite erfolgt eine Prüfung durch diesen Dienst. Siehe auch deren <a href="https://policies.google.com/privacy?hl=de" target="NeuerTab">Datenschutzerklärungen</a>. Sie können die über unsere Website erfassten, personenbezogenen Daten gemäss DSGVO jederzeit von uns löschen lassen. Dazu bitte eine E-Mail an  <a href="mailto:dsgvo@brandeis.de">dsgvo@brandeis.de</a> senden.
          </p>
        </div>
        <div className="register">
          <div className="register__container">
            {error && <div className="auth__error">{error}</div>}
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-Mail-Adresse"
              />
              <input
                type="password"
                name="password"
                value={password}
                required
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Kennwort"
              />
              <input
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Kennwort bestätigen"
              />
              <button>Registrieren</button>
            </form>
          </div>
            <h4>Weitere Aktionen</h4>
            <ul>
              <li>
                <Link to="/reset">Kennwort zurücksetzen</Link>
              </li>
              <li><Link to="/">Anmelden</Link>
              </li>
            </ul>
        </div>
      </View>
    </Layout>
  );
};

export default Register;
